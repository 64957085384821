<template>
    <Header/>
    <WhyDooPrime/>
    <AccountComparison/>
    <WhyChooseUs/>
    <OfferedPlatform/>
    <DepositMethod/>
    <ThaiBank/>
    <Contact/>
</template>

<script>
import Header from '../components/Header.vue'
import WhyDooPrime from '../components/WhyDooPrime.vue'
import AccountComparison from '../components/AccountComparison.vue'
import WhyChooseUs from '../components/WhyChooseUs.vue'
import OfferedPlatform from '../components/OfferedPlatform.vue'
import DepositMethod from '../components/DepositMethod.vue'
import ThaiBank from '../components/ThaiBank.vue'
import Contact from '../components/Contact.vue'
import 'wowjs/css/libs/animate.css'
import {WOW} from 'wowjs'

export default {
  name: 'App',
  components: {
    Header,
    WhyDooPrime,
    AccountComparison,
    WhyChooseUs,
    OfferedPlatform,
    DepositMethod,
    ThaiBank,
    Contact
  },
  mounted() {
    new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: false
    }).init();
  }
  
}
</script>

<style>
    @import "../less/main.less";
</style>