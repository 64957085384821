<template>
    <section id="header" class="header">
        <div class="container">
            <h1 class="wow fadeInUp">เริ่มเทรดอนุพันธ์ของเหรียญคริปโต</h1>
            <div class="list">
                <div class="wow fadeInUp" data-wow-delay="0.2s">1:500<span>เลเวอเรจ</span></div>
                <div class="icon-box"></div>
                <div class="wow fadeInUp web" data-wow-delay="0.4s">0.1 Pips<span>สเปรดต่ำ เพียง</span></div>
                <div class="icon-box web"></div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">24/7<span>การเทรด</span></div>
            </div>
            <div class="wow fadeInUp mobile" data-wow-delay="0.4s">$6<span>ค่าคอมมิสชั่น ต่อลอต</span></div>
            <Form/>
        </div>
    </section>
</template>

<script>
import Form from '../components/Form.vue'

export default {
  components: {
    Form
  }
}
</script>

<style lang="less">
    @cdn: 'http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/';

    .header {
        height:900px;
        padding-top:164px;
        background:url("@{cdn}header-bg.png") no-repeat center top;
        h1 {
            font-size: 54px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.02px;
            text-align: center;
            color: #fff;
            margin-top:0;
            margin-bottom:40px;
        }
        .list {
            max-width: 800px;
            width:100%;
            margin:auto;
            margin-bottom:70px;
            div {
                &:not(.icon-box) {
                    display: inline-block;
                    font-size: 40px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding:0 40px;
                }
                &.icon-box {
                    width:10px;
                    height:10px;
                    background:#fb2e34;
                    display:inline-block;
                    vertical-align: 52px;
                }
                span {
                    display:block;
                    font-size: 24px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    margin-top:8px;
                }
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        .header {
            padding-top: 89px;
            padding-bottom:95px;
            height:auto;
            h1 {
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
            }
            .list {
                div {
                    &:not(.icon-box) {
                        font-size: 30px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                    }
                    &.icon-box {
                        vertical-align: 35px;
                    }
                    span {
                        font-size: 18px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #fff;
                        margin-top:4px;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .mobile {display:none !important;}
    }
    @media only screen and (max-width: 767px) {
        .web {
            display:none !important;
        }
        .header {
            .list {
                display:flex;
                justify-content: space-between;
                margin-bottom:20px;
                max-width: 350px;
                div {
                    &:not(.icon-box) {
                        padding:0;
                    }
                     &.icon-box {
                        margin-top:13px;
                    }
                }
            }
            .mobile {
                display:block;
                margin-bottom: 50px;
                font-size: 30px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                span {
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    display:block;
                }
            }
        }
    }
    
</style>