<template>
    <section class="why-dooprime">
        <div class="container">
            <h2 class="wow fadeInUp">ทำไม่ถึงต้องเทรดอนุพันธ์ของเหรียญคริปโตกับ Doo Prime?</h2>
            <p class="wow fadeInUp" data-wow-delay="0.2s">Doo Prime มุ่งมั่นที่จะมอบประสบการณ์การซื้อขายที่ดีที่สุดให้กับคุณด้วยการสนับสนุนด้วยเครือข่ายที่แข็งแกร่ง ต้นทุนการทำธุรกรรมที่ดีเยี่ยม ขั้นตอนการฝากและถอนที่สะดวกรวดเร็ว และให้บริการระดับมืออาชีพรองรับหลายภาษา ทุกวัน ตลอด 24 ชั่วโมง</p>
            <div class="box-wrapper">
                <div class="wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'leverage.png'"/>
                    <div class="content">
                        <h3>การลงทุนที่มีเลเวอเรจสูง</h3>
                        <p>เลเวอเรจที่สูงและยืดหยุ่น เพื่อโอกาสทำกำไรอย่างต่อเนื่อง</p>
                    </div>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'fast-execution.png'" />
                    <div class="content">
                        <h3>มีใบอนุญาตและการกำกับดูแล</h3>
                        <p>เราปฏิบัติตามมาตรฐานด้านการกำกับดูแลทั้งหมดรวมถึงได้รับอนุญาตและมีการกำกับดูแลจาก FSC และ FCA</p>
                    </div>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'user-friendly.png'" />
                    <div class="content">
                        <h3>เริ่มต้นง่าย สบายใจ</h3>
                        <p>: เปิดบัญชี Doo Prime ง่ายๆ เพียงเงินฝากที่ 100 ดอลลาร์ และการซื้อขายเพียง 0.01 ล็อต</p>
                    </div>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'tech-devices.png'"  />
                    <div class="content">
                        <h3>เต็มอิ่มกับเทคโนโลยีขั้นสูง</h3>
                        <p>ที่ Doo Prime คุณสามารถเทรดอนุพันธ์ของคริปโตบน MT4 ด้วยเครื่องมือของผู้เชี่ยวชาญได้จากทุกอุปกรณ์ของคุณ</p>
                    </div>
                </div>
            </div>
            <a href="#header" class="btn toHeader wow fadeInUp" data-wow-delay="0.8s">ลงทะเบียน</a>
        </div>
    </section>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
        }
    } 
}
</script>

<style lang="less">
    .why-dooprime {
        padding: 120px 0;
        h2 {
            margin-bottom:15px;
        }
        .container {
            > p {
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #d8dce7;
                margin-bottom:50px;
            }
        }
        .box-wrapper {
            max-width:1340px;
            width:100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 30px;
            margin-bottom:50px;
            > div {
                padding: 59px 50px 59px 67px;
                text-align: left;
                border-radius: 10px;
                background-image: linear-gradient(to left, rgba(16, 16, 33, 0), #101021 0%);
                cursor:poiinter;
                display:flex;
                align-items: center;
                img {
                    width: auto;
                    height: 80px;
                    margin-right:73px;
                }
                .content {
                    color:#fff;
                }
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .why-dooprime {
            padding: 90px 0;
            .container {
                > p {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: center;
                    color: #d8dce7;
                    margin-bottom:40px;
                }
            }
            .box-wrapper {
                margin-bottom:0;
                > div {
                    padding: 59px 30px 59px;
                    &:last-child {
                        .content {
                            p {
                                margin-bottom:0;
                            }
                        }
                    }
                    img {
                        margin-right: 40px;
                        height: 64px;
                    }
                    .content {
                        h3 {
                            font-size: 20px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: rgba(255, 255, 255, 0.95);
                            margin-top:15px;
                            margin-bottom:15px;
                        }
                        p {
                            font-size: 16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #d8dce7;
                            margin:0 0 40px;
                        }
                    }
                } 
            }
            a {
                display: none;
            }
        } 
    }
    @media only screen and (min-width: 768px) {
        .why-dooprime {
            .box-wrapper {
                > div {
                    &:hover {
                        box-shadow: 0 0 20px 5px #292950;
                        background-image: linear-gradient(to right, rgba(16, 16, 33, 0) 0%, #101021 100%);
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .why-dooprime {
            .box-wrapper {
                display: block;
                > div {
                    display: block;
                    padding: 0;
                    background: none;
                    max-width: 320px;
                    text-align: center;
                    margin: auto;
                    img {
                        margin-right:0;
                    }
                }
            }
        }
    }
</style>