import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { plugin, defaultConfig } from '@formkit/vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'

createApp(App)
.use(router)
.use(VueAxios, axios)
.use(VueUniversalModal, {
    teleportTarget: '#modals'
})
.use(plugin, defaultConfig)
.mount('#app')
