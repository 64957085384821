<template>
    <section class="why-choose-us">
        <div class="container">
            <h2 class="wow fadeInUp">ทำไมถึงต้องเลือกเรา?</h2>
            <div class="columns">
                <div class="wow fadeInUp" data-wow-delay="0.2s">
                    <img v-bind:src="imageUrl + 'icon-monthly-turnover.png'"/>
                    <h3>มูลค่าการซื้อขายต่อเดือน</h3>
                    <p>> 5 แสนล้านดอลลาร์</p>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <img v-bind:src="imageUrl + 'icon-order-new.png'" />
                    <h3>ส่งคําสั่งซื้อ-ขายความเร็วสูง</h3>
                    <p>&lt; 50 มิลลิวินาที</p>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'icon-products.png'" />
                    <h3>ผลิตภัณฑ์ที่หลากหลายกว่า</h3>
                    <p>10,000 + รายการ</p>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.5s">
                    <img v-bind:src="imageUrl + 'icon-lowest-spread.png'"  />
                    <h3>สเปรดตํ่าที่สุด</h3>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'icon-leverage.png'"  />
                    <h3>เลเวอเรจ สูงสุดถึง <br>500 เท่า</h3>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.7s">
                    <img v-bind:src="imageUrl + 'icon-services.png'"  />
                    <h3>โดยทีมงานที่พร้อม<br>ให้บริการอย่างมืออาชีพ</h3>
                </div>
            </div>
            <a href="#header" class="btn toHeader wow fadeInUp" data-wow-delay="0.8s">ลงทะเบียน</a>
        </div>
    </section>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
        }
    } 
}
</script>

<style lang="less">
    .why-choose-us {
        padding: 120px 0;
        h2 {
            margin-bottom:50px;
        }
        .columns {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 50px;
            margin-bottom:50px;
            > div {
                text-align: center;
                img {
                    margin-bottom:20px;
                }
                h3 {
                    font-size: 24px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.95);
                    margin: 0;
                }
                p {
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #d8dce7;
                    margin:0;
                }
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .why-choose-us {
            padding: 90px 0 20px;
            .columns {
                > div {
                    img {
                        margin-bottom: 15px;
                        width: 64px;
                    }
                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.95);
                    }
                    p {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #d8dce7;
                    }
                }
            }
            a {
                display:none;
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .why-choose-us {
            .columns {
                display: block;
                margin-bottom:0;
                > div {
                    margin-bottom:40px;
                    &:last-child {
                        margin-bottom:0;
                    }
                    img {
                        margin-bottom: 15px;
                        width: 64px;
                    }
                } 
            }
            
        }
    }
</style>