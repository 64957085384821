<template>
    <section class="deposit-method">
        <div class="container">
            <h2 class="wow fadeInUp">ช่องทางการฝากเงิน ที่หลากหลาย</h2>
            <div class="columns">
                <div class="method divider wow fadeInUp" data-wow-delay="0.2s">
                    <img v-bind:src="imageUrl + 'deposit.png'"/>
                    <h3>องทางการฝากเงินที่หลากหลาย</h3>
                </div>
                <div class="method divider wow fadeInUp" data-wow-delay="0.3s">
                    <img v-bind:src="imageUrl + 'bank.png'"/>
                    <h3>ดําเนินการผ่านธนาคารและสถาบันการเงิน</h3>
                </div>
                <div class="method divider wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'card.png'"/>
                    <h3>ดําเนินการด้วยบัตร Visa หรือ Master Card</h3>
                </div>
                <div class="method divider wow fadeInUp" data-wow-delay="0.5s">
                    <img v-bind:src="imageUrl + 'e-wallet.png'"/>
                    <h3>E-wallet</h3>
                </div>
                <div class="method wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'digital-currency.png'"/>
                    <h3>สกุลเงินดิจิตอล</h3>
                </div>

                <div class="big4 wow fadeInUp" data-wow-delay="0.3s">
                    <img v-bind:src="imageUrl + 'deposit-1.png'"/>
                </div>
                <div class="big4 wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'bank-1.png'"/>
                </div>
                <div class="big4 wow fadeInUp" data-wow-delay="0.5s">
                    <img v-bind:src="imageUrl + 'card-1.png'"/>
                </div>
                <div class="big4 wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'e-wallet-1.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.7s">
                    <img v-bind:src="imageUrl + 'digital-currency-1.png'"/>
                </div>

                <div class="wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'deposit-2.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.5s">
                    <img v-bind:src="imageUrl + 'bank-2.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'card-2.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.7s">
                    <img v-bind:src="imageUrl + 'e-wallet-2.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.8s">
                    <img v-bind:src="imageUrl + 'digital-currency-2.png'"/>
                </div>

                <div class="wow fadeInUp" data-wow-delay="0.5s">
                    <img v-bind:src="imageUrl + 'deposit-3.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'bank-3.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.7s">
                    <img v-bind:src="imageUrl + 'card-3.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.8s">
                    <img v-bind:src="imageUrl + 'e-wallet-3.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="1.0s">
                    <img v-bind:src="imageUrl + 'digital-currency-3.png'"/>
                </div>
            </div>
            <a href="#header" class="btn toHeader wow fadeInUp" data-wow-delay="0.4s">ลงทะเบียน</a>
        </div>
    </section>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
        }
    }
}
</script>

<style lang="less">
    .deposit-method {
        padding: 120px 0;
        h2 {
            margin-bottom:50px;
        }
        .columns {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: 1fr auto;
            grid-column-gap: 50px;
            grid-row-gap: 50px;
            margin-bottom:50px;
            > div {
                text-align: center;
                position:relative;
                &.divider {
                    &:after {
                        content:'';
                        width: 1px;
                        height: 101px;
                        background:#292950;
                        position:absolute;
                        right:-25px;
                        top:10%;
                    }
                }
                img {
                    margin-bottom:20px;
                }
                h3 {
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #d8dce7;
                    margin: 0;
                }
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .deposit-method {
            padding: 20px 0 31px;
            .columns {
                display:block;
                margin-bottom:0;
                max-width: 600px;
                margin: 0 auto;
                > div {
                    display: inline;
                    vertical-align: middle;
                    margin: 0;
                    &.method {
                        display:inline-block;
                        width:50%;
                        margin-bottom:40px;
                        &:first-child{
                            display:block;
                            width:100%;
                        }
                        &:after {
                            display:none;
                        }
                        img {
                            max-width: 55px;
                        }
                    }
                    &.big4 {
                        img {
                            width: 17%;
                            max-width:76px;
                        }
                    }
                    h3 {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: #d8dce7;
                    }
                    img {
                        margin: 0 7.5px 15px;
                        width: 22%;
                    }
                }
            }
            a {
                display:none;
            }
        }
     }
</style>