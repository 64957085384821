<template>
    <section class="offered-platform">
        <div class="container">
            <h2 class="wow fadeInUp">Offered Platform in Thailand</h2>
            <div class="columns">
                <div class="wow fadeInUp" data-wow-delay="0.2s">
                    <img v-bind:src="imageUrl + 'offered-platform-mt-4.png'"/>
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.3s">
                    <img v-bind:src="imageUrl + 'offered-platform-tradingview.png'" />
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.4s">
                    <img v-bind:src="imageUrl + 'offered-platform-mam-pamm.png'" />
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.5s">
                    <img v-bind:src="imageUrl + 'offered-platform-intrade.png'"  />
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'offered-platform-fixprotocol.png'"  />
                </div>
                <div class="wow fadeInUp" data-wow-delay="0.7s">
                    <img v-bind:src="imageUrl + 'offered-platform-outrade.png'"  />
                </div>
            </div>
            <a href="#header" class="btn toHeader wow fadeInUp" data-wow-delay="0.8s">ลงทะเบียน</a>
        </div>
    </section>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
        }
    } 
}
</script>

<style lang="less">
    .offered-platform {
        padding: 289px 0;
        position:relative;
        &:after {
            content:'';
            position: absolute;
            top: 10%;
            right: 0;
            height: 80%;
            width: 100%;
            background: #101021;
            transform: skew(0deg, -9deg);
            z-index: -1;
        }
        h2 {
            margin-bottom:50px;
        }
        .columns {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 50px;
            margin-bottom:50px;
            > div {
                text-align: center;
                img {
                    margin-bottom:20px;
                    padding: 0 14px;
                }
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .offered-platform {
            padding: 108px 0 95px;
            h2 {
                margin-bottom:40px;
            }
            .columns {
                display: block;
                max-width: 600px;
                margin: auto;
                > div {
                    display:inline-block;
                    width: 50%;
                    vertical-align: middle;
                    img {
                        max-width: 200px;
                        width: 100%;
                    }
                }
            }
            a {
                display:none;
            }
        }
    }
</style>