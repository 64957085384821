<template>
    <section class="account-comparison">
        <div class="container">
            <h2 class="wow fadeInUp">การเปรียบเทียบบัญชี</h2>
            <div class="tab">
                <button class="tablinks wow fadeInUp" data-wow-delay="0.2s" v-on:click="compareList($event, 'STP')" id="defaultOpen">STP</button>
                <button class="tablinks wow fadeInUp" data-wow-delay="0.4s" v-on:click="compareList($event, 'ECN')">ECN</button>
                <button class="tablinks wow fadeInUp" data-wow-delay="0.6s" v-on:click="compareList($event, 'CENT')">CENT</button>
            </div>
            <div class="comparison-list wow fadeInUp" data-wow-delay="0.2s"></div>
            <a href="#header" class="btn toHeader wow fadeInUp" data-wow-delay="0.4s">ลงทะเบียน</a>
        </div>
    </section>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            tableData : [
                [
                    "STP",
                    "Account Currency",
                    "USD",
                    "Spread",
                    "1.1",
                    "Minimum Deposit",
                    "100",
                    "Leverage",
                    "1:100 - 1:500",
                    "Minimum Trading Lot",
                    "0.01",
                    "Maximum lot per single hand",
                    "100",
                    "Maximum floating lot per MT Account",
                    "Unlimited",
                    "Maximum order including pending orders",
                    "500",
                    "Margin Call",
                    "100%",
                    "Stop Out",
                    "30%",
                    "Commission per lot",
                    "No",
                    "Swap Free",
                    "Yes"
                ],
                [
                    "ECN",
                    "Account Currency",
                    "USD",
                    "Spread",
                    "0.1",
                    "Minimum Deposit",
                    "500",
                    "Leverage",
                    "1:100 - 1:500",
                    "Minimum Trading Lot",
                    "0.01",
                    "Maximum lot per single hand",
                    "100",
                    "Maximum floating lot per MT Account",
                    "Unlimited",
                    "Maximum order including pending orders",
                    "500",
                    "Margin Call",
                    "100%",
                    "Stop Out",
                    "30%",
                    "Commission per lot",
                    "$6",
                    "Swap Free",
                    "Yes"
                ],
                [
                    "CENT",
                    "Account Currency",
                    "USC",
                    "Spread",
                    "1.8",
                    "Minimum Deposit",
                    "20",
                    "Leverage",
                    "1:100 - 1:500",
                    "Minimum Trading Lot",
                    "0.01",
                    "Maximum lot per single hand",
                    "100",
                    "Maximum floating lot per MT Account",
                    "Unlimited",
                    "Maximum order including pending orders",
                    "500",
                    "Margin Call",
                    "100%",
                    "Stop Out",
                    "30%",
                    "Commission per lot",
                    "No",
                    "Swap Free",
                    "Yes"
                ]
            ]
        }
    },
    methods: {
        generateList() {
            let mainTable = document.querySelector(".comparison-list");
           
            for(let i=0 ; i < this.tableData.length; i++){
                let tableHead = ``;
                let tableContent = ``;
                let title = this.tableData[i][0];
                this.tableData[i].map( (item, index) => {
                    if(index === 0){
                        tableHead = `<div class="thead">` + item + `</div>`;
                        return
                    }
                    tableContent += `<div class="tcontent">` + item + `<hr/></div>`
                })
                mainTable.innerHTML += `<div id="` + title + `" class="wrapper tabcontent">` + tableHead + `<div class="wrapper-content">` + tableContent + `</div></div>`;
            }
        },
        compareList: function(e, cityName) {
            let i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(cityName).style.display = "block";
            e.currentTarget.className += " active";
        }
    },
    mounted() {
        this.generateList();
        document.getElementById("defaultOpen").click();
    }
}
</script>

<style lang="less">
    .account-comparison {
        h2 {
            margin-bottom:50px;
        }
        .tab {
            display:none;
        }
        .comparison-list {
            display: flex;
            margin-bottom:50px;
            .wrapper {
                max-width: 400px;
                width: 100%;
                margin: 0 15px;
            }
            .wrapper-content {
                padding:22px 15px 20px;
                border-radius: 0 0 10px 10px;
                border: solid 1px #202040;
                border-top: 0;
                background-color: #171729;
                .tcontent {
                    position:relative;
                    &:nth-child(odd){
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        text-align: center;
                        color: #d8dce7;
                        padding-top:19px;
                        padding-bottom: 5px;
                        hr {
                            display:none;
                        }
                    }
                    &:nth-child(even){
                        font-size: 24px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.95);
                    }
                    &:last-child {
                        hr {
                            display:none;
                        }
                    }
                    hr {
                        max-width: 300px;
                        height: 1px;
                        border: 0;
                        border-top: 1px solid #292950;
                        margin:0 auto;
                        margin-top: 20px;
                    }
                }
            }
            .thead {
                padding: 35px 15px;
                border-radius: 10px 10px 0 0 ;
                background:#101021;
                position: relative;
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                cursor:pointer;
                margin-bottom: 5px;
                &:hover, &.active {
                    background-color: #292950;
                    &:after {
                        content:'';
                        width:200px;
                        height:1px;
                        background:#ff0000;
                        position:absolute;
                        left:50%;
                        bottom:0;
                        margin-left:-100px;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .account-comparison {
            .comparison-list {
                .wrapper {
                    display:inline-block !important;
                }
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .account-comparison {
            h2 {
                margin-bottom:40px;
            }
            .tab {
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                .tablinks {
                    max-width:100px;
                    width: 100%;
                    padding: 8px 0;
                    border-radius: 10px;
                    border: solid 1px #202040;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.7px;
                    text-align: center;
                    color: #b5b5b5;
                    background: none;
                    margin-right: 10px;
                    cursor:pointer;
                    &:last-child {
                        margin-right:0;
                    }
                    &.active {
                        border-color: #d91d22;
                        color: #fff;
                    }
                }
            }
            .comparison-list {
                margin-bottom:0;
                .thead {
                    font-size: 30px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    padding: 24px 15px;
                    background-color: #292950;
                    &:after {
                        content:'';
                        width:200px;
                        height:1px;
                        background:#ff0000;
                        position:absolute;
                        left:50%;
                        bottom:0;
                        margin-left:-100px;
                    }
                }
                .wrapper {
                    margin: auto;
                    animation: article-active-appear;
                    animation-duration: 5s;
                }
                @keyframes article-active-appear {
                    0% { transform: translateY(5%); opacity: 0 }
                    20% { transform: translateY(0px); opacity: 1 }
                    90% { transform: translateY(0px); opacity: 1 }
                    100% { opacity: 0 }
                }
                .wrapper-content {
                    padding: 16px 15px 30px;
                    .tcontent {
                        &:nth-child(odd){
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.42;
                            letter-spacing: normal;
                            text-align: center;
                            color: #d8dce7;
                            padding-top: 14px;
                        }
                        &:nth-child(even){
                            font-size: 20px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: rgba(255, 255, 255, 0.95);
                        }
                        hr {
                            margin-top:15px;
                        }
                    }
                }
            }
            a {
                display:none;
            }
        }
    }
</style>