<template>
    <section class="contact">
        <div class="container">
            <h2 class="wow fadeInUp">ติดต่อ<br>Doo Prime Thailand</h2>
            <p class="wow fadeInUp" data-wow-delay="0.2s">Email:  th.support@dooprime.com</p>
            <p class="wow fadeInUp" data-wow-delay="0.3s">Line ID: @dooprimethailand</p>
            <p class="wow fadeInUp" data-wow-delay="0.4s">Or Scan QR Code:</p>
            <img v-bind:src="imageUrl + 'th-line-qr-code.png'" class="qr-code wow fadeInUp" data-wow-delay="0.5s"/>
            <div class="social-media">
                <a href="https://page.line.me/dooprimethailand" target="_blank" class="wow fadeInLeft" data-wow-delay="0.6s">
                    <img v-bind:src="imageUrl + 'icon-line.png'"/>
                </a>
                <a href="https://www.facebook.com/DooPrimeThai" target="_blank" class="wow fadeInLeft" data-wow-delay="0.7s">
                    <img v-bind:src="imageUrl + 'icon-fb.png'"/>
                </a>
                <a href="https://www.instagram.com/dooprimethai/?hl=en" target="_blank" class="wow fadeInLeft" data-wow-delay="0.8s">
                    <img v-bind:src="imageUrl + 'icon-instagram.png'"/>
                </a>
                <a href="https://www.youtube.com/channel/UCGf14tdX9TO-B6mdO77kSMg" target="_blank" class="wow fadeInLeft" data-wow-delay="0.9s">
                    <img v-bind:src="imageUrl + 'icon-youtube.png'"/>
                </a>
            </div>
            <img v-bind:src="imageUrl + 'bangkok-bg.png'" class="bg-img wow fadeInRight" data-wow-delay="0.4s"/>
        </div>
    </section>
    <div class="copyright qr-code wow fadeInUp" data-wow-delay="0.2s">เว็บไซต์นี้เป็นเจ้าของและดำเนินการโดย Doo Prime Vanuatu Limited (Reg.No. 700238) © 2022 Doo Prime。สงวนลิขสิทธิ์</div>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
        }
    }
}
</script>

<style lang="less">
    .contact {
        padding: 120px 0;
        text-align: left;
        position:relative;
        display: flex;
        h2 {
            margin-bottom:50px;
            text-align: left;
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
        }
        p {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.95);
            margin:0;
            margin-bottom:20px;
        }
        .qr-code {
            max-width:250px;
            width: 100%;
            margin-top:30px;
            margin-bottom:53px;
        }
        .social-media {
            display:flex;
            align-items: center;
            a {
                margin-right:25px;
                width:50px;
                text-align: center;
            }
        }
        .bg-img {
            position: absolute;
            right: -86px;
            top: 0px;
        }
    }
    .copyright {
        padding:18px 15px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: 1.5px;
        color: #fff;
        background-color: #101021;
    }

    @media only screen and (max-width: 1470px) and (min-width: 1200px) {
        .contact {
            .bg-img {
                right: 0px;
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        .contact, .contact h2 {
            text-align: center;
        }
        .contact {
            padding:20px 0 91px;
            h2 {
                font-size: 32px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                margin-bottom:40px;
            }
            p {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: rgba(255, 255, 255, 0.95);
                margin-bottom:15px;
            }
            .qr-code {
                margin-top: 25px;
                margin-bottom:40px;
            }
        }
        .contact .social-media {
            justify-content: center;
            a {
                margin: 0 12.5px;
            }
        }
        .bg-img {
            display:none;
        }
        .copyright {
            padding: 15px 28px;
        }
    }
</style>