<template>
    <section class="thai-bank">
        <div class="container">
            <h2 class="wow fadeInUp">ฝากถอนง่าย ผ่านธนาคารไทย</h2>
            <ul class="points">
                <li class="wow fadeInUp" data-wow-delay="0.2s"><span></span>เติมเงินเข้าพอร์ตทันที รวดเร็วทันใจ</li>
                <li class="wow fadeInUp" data-wow-delay="0.3s"><span></span>ด้วยระบบ QR Code ที่รองรับกว่า 10 ธนาคาร</li>
                <li class="wow fadeInUp" data-wow-delay="0.4s"><span></span>และถอนเงินผ่านภายในเวลาไม่เกิน 24 ชั่วโมง</li>
            </ul>
            <a href="#header" class="btn toHeader wow fadeInUp" data-wow-delay="0.5s">ลงทะเบียน</a>
            <img v-bind:src="imageUrl + 'thai-bank.png'" class="wow fadeInRight" data-wow-delay="0.4s"/>
        </div>
    </section>
</template>

<script type="text/babel">
export default {
    data() {
        return {
            imageUrl: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
        }
    }
}
</script>

<style lang="less">
    .thai-bank {
        padding: 120px 0;
        text-align: left;
        min-height:1080px;
        position:relative;
        display: flex;
        &:after {
            content:'';
            position: absolute;
            top: 10%;
            right: 0;
            height: 80%;
            width: 100%;
            background: #101021;
            transform: skew(0deg, 9deg);
            z-index: -1;
        }
        h2 {
            margin-bottom:50px;
            text-align: left;
        }
        .points {
            margin-bottom:50px;
            padding-left: 0;
            span {
                width:12px;
                height:12px;
                background-color: #d91d22;
                display:inline-block;
                position:absolute;
                left:0;
                top:11px;
            }
            li {
                font-size: 24px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: rgba(255, 255, 255, 0.95);
                margin-bottom:20px;
                padding-left: 35px;
                position:relative;
            }
        }
        a {
            margin:0;
            display:none;
        }
        
    }
    @media only screen and (min-width: 1200px) {
        .thai-bank {
            a {
                display:block;
            }
            img {
                position: absolute;
                right: -86px;
                top: -44px;
            }
        }
    }
    @media only screen and (max-width: 1470px) and (min-width: 1200px) {
        .thai-bank {
            img {
                right: 0px;
                width: 600px;
            }
        }
    }
    @media only screen and (max-width: 1199px) {
        .thai-bank {
            min-height: 788px;
            padding: 109px 0 94px;
            .container {
                max-width:320px;
            }
            h2 {
                font-size: 32px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #fff;
                margin-bottom:40px;
            }
            .points {
                margin-bottom:40px;
                span {
                    top:5px;
                }
                li {
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: rgba(255, 255, 255, 0.95);
                    margin-bottom: 15px;
                }
            }
            img {
                max-width: 100%;
            }
        }
    }
</style>